import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { Container, PrimaryBtn } from "components/library"
import { ArrowRight } from "react-feather"
import { NotionArticle, useArticle } from "utils"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import { TranslationKey } from "types/translation"
import { DownloadDocument } from "components/common/download-document/component"
import { CtaBlock } from "components/common/cta-block"
import { getDataManagementLink, getIndustryNewsLink, getTLD } from "utils/general/links"

const caseStudyFile =
  "https://polymerize-misc.s3.ap-southeast-1.amazonaws.com/White+Paper+1+-+Explainable+AI.pdf"

type P = {
  pageContext: {
    article: NotionArticle
  }
}

const WhitePaper = ({ pageContext: { article } }: P) => {
  const [open, setOpen] = useState(false)
  const { author, title } = useArticle({ article })
  const image =
    "https://res.cloudinary.com/polymerize/image/upload/v1654250568/blog/white-paper-1_f8wj6q.webp"
  const { t } = useTranslation()

  function afterSubmit() {
    window.trackWhitePaperDownload()
  }

  return (
    <>
      <DownloadDocument
        open={open}
        setOpen={setOpen}
        file={caseStudyFile}
        document={title}
        documentName="White Paper"
        afterSubmit={afterSubmit}
        showMoreDetails
      />

      <main className="flex flex-col">
        <section className="bg-hero-bg-flat-mobile sm:bg-hero-bg-flat bg-no-repeat bg-cover pb-12 sm:pb-20 pt-32 sm:pt-[180px] -mt-[115px]">
          <Container className="flex flex-col gap-4 sm:gap-12 text-white">
            <span className="text-lg sm:text-2xl">
              {t("common.words.white-paper")}
            </span>

            <h1 className="text-2xl font-bold sm:text-5xl lg:text-6xl">
              {t("white-paper-page.hero.heading")}
            </h1>

            <PrimaryBtn
              className="bg-theme-orange hover:bg-theme-orange/90 md:text-lg max-w-max py-4 px-6"
              onClick={() => setOpen(true)}
            >
              {t("white-paper-page.hero.cta")}
            </PrimaryBtn>
          </Container>
        </section>

        {/* CONTENT */}
        <Container className="flex gap-10 py-20 flex-col sm:flex-row items-start justify-center">
          <div className="flex flex-col gap-10 sm:border-r sm:pr-10">
            <article className="prose w-full sm:max-w-3xl prose-li:marker:text-inherit">
              <img src={image} alt={title} className="w-full h-auto" />

              <div>
                <p>{t("white-paper-page.article.para.1")}</p>
                <p>{t("white-paper-page.article.para.2")}</p>
              </div>
            </article>

            <button
              className="text-lg cursor-pointer text-left"
              onClick={() => setOpen(true)}
            >
              <Trans
                i18nKey="white-paper-page.article.cta"
                components={{
                  spantag: <span />,
                }}
              />
            </button>
          </div>

          {/* AUTHOR */}
          <div className="w-80 shrink-0 sticky flex flex-col gap-12 top-8 flex-initial">
            <div className="flex gap-4 items-center">
              <img
                src={author.image}
                alt={
                  t(
                    `common.people.${author.name}.name` as TranslationKey
                  ) as string
                }
                className="w-16 h-16 shrink-0 rounded-full overflow-hidden"
              />

              <div className="flex flex-col">
                <p className="font-semibold">
                  {
                    t(
                      `common.people.${author.name}.name` as TranslationKey
                    ) as string
                  }
                </p>
                <p>
                  {
                    t(
                      `common.people.${author.name}.bio` as TranslationKey
                    ) as string
                  }
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-3">
              <h3 className="text-xl uppercase text-neutral-600">
                {t("common.words.contact")}
              </h3>
              <div className="w-full h-[1px] bg-black/30"></div>
              <ul className="flex flex-col px-4 py-2 gap-2">
                <li>
                  <Link
                    to="/contact-us/"
                    className="flex items-center gap-2 uppercase text-theme-orange"
                  >
                    <span>{t("common.words.contact-us")}</span>
                    <ArrowRight size={20} />
                  </Link>
                </li>
              </ul>
            </div>

            <div className="flex flex-col gap-3">
              <h3 className="text-xl uppercase text-neutral-600">
                {t("common.words.blog")}
              </h3>
              <div className="w-full h-[1px] bg-black/30"></div>
              <ul className="flex list-disc flex-col px-8 py-2 gap-2">
                <li>
                  <a
                    className="underline"
                    target="_blank"
                    rel="noreferrer"
                    href={`https://blog.polymerize.${getTLD(process.env.GATSBY_LANGUAGE_SELECTED)}/category/polymerize`
                    }
                  >
                    Polymerize
                  </a>
                </li>
                <li>
                  <a
                    className="underline"
                    target="_blank"
                    rel="noreferrer"
                    href={`https://blog.polymerize.${getTLD(process.env.GATSBY_LANGUAGE_SELECTED)}/category/ai/ml`
                    }
                  >
                    AI/ML
                  </a>
                </li>
                <li>
                  <a
                    className="underline"
                    target="_blank"
                    rel="noreferrer"
                    href={getDataManagementLink(process.env.GATSBY_LANGUAGE_SELECTED)
                    }
                  >
                    {t("common.words.data-management")}
                  </a>
                </li>
                <li>
                  <a
                    className="underline"
                    target="_blank"
                    rel="noreferrer"
                    href={
                      getIndustryNewsLink(process.env.GATSBY_LANGUAGE_SELECTED)
                    }
                  >
                    {t("common.words.industry-news")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </Container>

        <CtaBlock className="hidden sm:inline-flex" />
      </main>
    </>
  );
}

export default WhitePaper

// FOR LANGUAGE TRANSLATION
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`
